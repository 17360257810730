import React from "react"
import PropTypes from "prop-types"
import LawyerItem from "./LawyerItem"

const LawyerBlock = ({ locations, locationRefs }) => {
  const lawyerList = locations.map((location, index) => (
    <LawyerItem
      key={index}
      location={location}
      isLast={index < locations.length - 1}
      locationRefs={locationRefs}
      index={index}
    />
  ))

  return <>{lawyerList}</>
}

LawyerBlock.propTypes = {
  locations: PropTypes.array,
  locationRefs: PropTypes.object,
}

export default LawyerBlock
