import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Lawyer = ({ person: { name, profile, position, slug } }) => {
  return (
    <div className="mb-6 last:mb-0 lg:w-1/2 lg:pl-12 lg:mb-12">
      <Link to={"/lawyer/" + slug}>
        <div className="relative overflow-hidden">
          <GatsbyImage alt={name} image={profile} />
          <div className="max-w-full absolute z-10 top-0 left-0 h-full w-full transition-all transform duration-500 hover:scale-110">
            <GatsbyImage alt={name} image={profile} />
          </div>
        </div>
        <div className="text-center mt-3">
          <h3 className="text-xl font-serif text-gray-700 lg:text-2xl">
            {name}
          </h3>
          <p className="text-sm text-gray-500 mt-1 lg:text-base">{position}</p>
        </div>
      </Link>
    </div>
  )
}

const LawyerItem = ({
  location: { locationName, lawyers },
  locationRefs,
  index,
  isLast,
}) => {
  const lawyerItems = lawyers.map((person, index) => (
    <Lawyer key={index} person={person} />
  ))

  return (
    <div
      className="relative lg:flex lg:flex-wrap"
      ref={locationRefs.current[index]}
    >
      <div id={locationName} className="absolute top-0 -mt-32" />
      {lawyerItems}
      <hr
        className={`w-full my-6 sm:my-16 text-gray-300 ${
          isLast ? "" : "hidden"
        }`}
      />
    </div>
  )
}

Lawyer.propTypes = {
  person: PropTypes.object,
  _index: PropTypes.number,
}

LawyerItem.propTypes = {
  location: PropTypes.object,
  isLast: PropTypes.bool,
  locationRefs: PropTypes.object,
  index: PropTypes.number,
}

export default LawyerItem
