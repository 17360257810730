import { GatsbyImage } from "gatsby-plugin-image"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import React, { useEffect, useRef, useState } from "react"
import LogoSquare from "../assets/img_zl-logo_logo-only_square.svg"
import PropTypes from "prop-types"
import Layout from "../components/Layout"
import useLandingImage from "../hooks/useLandingImage"
import useMetadata from "../hooks/useMetadata"
import LocationSlide from "../components/LocationSlide"
import LawyerBlock from "../components/about/LawyerBlock"
import useLocationLawyerData from "../hooks/useLocationLawyerData"

const About = () => {
  const { locationPeople: locations } = useLocationLawyerData()
  const { teamOGImage, about } = useLandingImage()
  const { siteUrl } = useMetadata()
  const metaData = {
    metaTitle: "律師團隊",
    metaDescription:
      "喆律的律師團隊由執業超過10年的資深律師和前法官所組成，致力於提供客戶高品質的專業法律服務。",
    canonical: `${siteUrl}/about`,
  }
  const ogData = {
    ogImage: `https:${teamOGImage}`,
    ogImageAlt: metaData.metaTitle,
  }

  const [locationNavIndex, setLocationNavIndex] = useState(0)
  const [locationNavStyle, setLocationNavStyle] = useState({
    width: 0,
    left: 0,
  })

  const locationRefs = useRef(locations.map(() => React.createRef()))
  const currentSubMenuRef = useRef(null)

  const handleScroll = () => {
    let scrollTop = window.pageYOffset
    let currentIndex = 0
    const offset = 250
    const sections = locationRefs.current

    sections.forEach((section, index) => {
      const offsetTop = section.current.offsetTop - offset
      if (scrollTop >= offsetTop) {
        currentIndex = index
      }
    })

    setLocationNavIndex(currentIndex)
    setLocationNavStyle({
      width: currentSubMenuRef.current.offsetWidth,
      left: currentSubMenuRef.current.offsetLeft,
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  })

  return (
    <Layout transParentMenu={false}>
      <GatsbySeo
        title={metaData.metaTitle}
        description={metaData.metaDescription}
        canonical={metaData.canonical}
        openGraph={{
          title: metaData.metaTitle,
          description: metaData.metaDescription,
          url: metaData.canonical,
          images: [
            {
              url: ogData.ogImage,
              alt: ogData.ogImageAlt,
            },
          ],
          type: "website",
        }}
      />
      <div className="about-page mt-nav">
        <div className="w-full h-62 sm:h-128">
          <GatsbyImage
            className="w-full h-full"
            image={about}
            alt={metaData.metaTitle}
            objectFit="cover"
            imgStyle={{
              objectPosition: "top center",
            }}
          />
        </div>
      </div>
      <section className="bg-gray-200">
        <div className="container lg:flex">
          <div className="text-center lg:text-left lg:w-64">
            <LogoSquare className="w-32 h-32 inline-block lg:w-64 lg:h-64" />
          </div>
          <div className="lg:pl-12">
            <div className="text-center mt-6 lg:mt-0 lg:text-left">
              <h2 className="title-border">喆律團隊</h2>
            </div>
            <p className="text-gray-500 mt-6 lg:text-xl">
              協助當事人圓滿解決法律爭議，是我們一直以來恪守的原則 -
              喆律法律事務所 雷皓明律師
            </p>
            <p className="text-gray-500 mt-3 lg:text-xl">
              本所主持律師雷皓明有感於最貼近人民生活的法律，卻因為屬於高專業性知識領域的緣故，使大眾對法律往往有疏離感與未知感，因而怯步或無從使用法律服務，所以本所致力於讓法律更親近民眾，除了建立各項專業法律領域品牌外，更撰寫大量可靠的法律知識內容及製作各類法律工具等，讓民眾可以充分了解自身權利及勝敗訴率後，再決定使用合適的法律服務方案。
            </p>
          </div>
        </div>
      </section>
      <div id="lawyers" className="pt-16 sm:pb-16 lg:pb-20">
        <div className="container sm:flex sm:mx-auto sm:px-8">
          <div className="phone:sticky phone:top-nav phone:z-20 sm:w-2/5 lg:w-1/4">
            <nav className="location-nav bg-white sticky top-nav z-10">
              <ul className="relative w-full p-0 flex sm:block sm:pt-4">
                <LocationSlide
                  locations={locations}
                  locationNavIndex={locationNavIndex}
                  currentSubMenuRef={currentSubMenuRef}
                  pageName={"about"}
                />
                <div
                  className="nav-active-bar phone:border-b phone:border-primary phone:absolute phone:bottom-0 phone:left-0 phone:w-full phone:z-10 phone:transition-all phone:duration-300 phone:ease-in phone:w-20"
                  style={locationNavStyle}
                />
              </ul>
            </nav>
          </div>
          <div className="sm:w-3/5 lg:w-3/4">
            <div className="lawyer-list mt-8 sm:pl-12 sm:mt-0 lg:pl-0">
              <LawyerBlock locations={locations} locationRefs={locationRefs} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default About

About.propTypes = {
  data: PropTypes.object,
}
